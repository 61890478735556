@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

:root {
  --primary-white: #fff;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-orange: #f9ab00;
  --primary-dark: rgba(255, 255, 255, 0);
  --primary-red: #ef4035;
  --priary-hover-red: #f33501;
  --primary-black: #000000;
}


body {
  font-family: "Roboto Condensed", sans-serif !important;
}

/********************************** NAVBAR ********************************/

.navbar-brand-name>p {
  color: var(--primary-white);
  text-transform: uppercase;
  color: var(--primary-white);
  padding-top: 1.1rem;
  position: absolute;

}

/******************************** Menu Links ******************************/
nav a.nav-link {
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav>li>a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-white);
  border-top: 0.1875rem solid var(--primary-dark);
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 4rem;
}

.navbar-nav>li>a.active,
.navbar-nav>li>a.active:focus {
  border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-nav>li>a:hover {
  border-top: 0.1875rem solid var(--primary-orange);
}

/********************************** Header ********************************/

.header-wrapper {
  position: relative;
  background: url('assets/wall-and-laptop-background.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
}

.main-info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.main-info h1 {
  color: var(--primary-orange);
  text-transform: uppercase;
}

.buttons-wrapper {
  display: flex;
}

/******************************* Typed Text *****************************/
.typed-text {
  font-size: 2rem;
  color: var(--primary-white)
}

@media(max-width: 768px) {
  .main-info h1 {
    font-size: 1.5rem !important;
  }

  .typed-text {
    font-size: 1.7rem !important;
  }
}

/******************************* Down Arrow *****************************/
.down-arrow {
  position: absolute;
  top: calc(87vh - 120px);
  left: calc(50% - 14px);
  width: 0;
  height: 30px;
  border: 2px solid;
  border-radius: 2px;
  color: var(--primary-white);
  animation: jumpInfinite 1.5s infinite;
}

.down-arrow:after {
  content: " ";
  position: absolute;
  top: 12px;
  left: -8px;
  width: 16px;
  height: 16px;
  border-bottom: 4px solid;
  border-right: 4px solid;
  border-radius: 4px;
  transform: rotateZ(45deg);
}

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}

/******************************** Skills ******************************/
.skills-heading {
  color: var(--primary-orange);
  text-transform: uppercase;
}

.skills-img-wrapper {
  height: 7rem;
  width: 7rem;
}

.skills-img-text {
  text-transform: uppercase;
  margin-bottom: 3rem !important;
}

/*********************** HEADER MAIN OFFER BUTTON *********************/
.btn-main-offer {
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin: 2rem;
  text-decoration: none;
  background: inherit;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--priary-hover-red);
  color: var(--primary-white);
  transition: .2s ease-in-out;
}

/******************************* About Me *****************************/
.photo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 17rem;
  height: 20rem;
  border-radius: 50%;
  padding: .3rem;
  border: 1px solid var(--primary-orange);
}

.about-heading {
  color: var(--primary-orange);
  text-transform: uppercase;
}

@media(max-width: 468px) {
  .profile-img {
    width: 13rem;
    height: 15rem;
  }

}

/******************************* EXPERIENCE *****************************/

.experience {
  padding: 0.7rem;
}

.experience p {
  color: var(--primary-grey);
}

.experience ul li {
  color: var(--primary-grey);
}

.experience .notable-classes {
  color: var(--primary-orange);
  font-weight: bold;
}

.experience-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.experience h1 {
  text-transform: uppercase;
  color: var(--primary-orange);
}

.experience-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: var(--primary-grey);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid var(--primary-dark);
  background: var(--primary-orange);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--primary-dark-grey);
  direction: ltr;
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: var(--primary-red);
  font-weight: 900;
}

/************* MEDIA *****************/
@media(max-width: 768px) {
  .experience .container {
    padding-left: 0;
  }
  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  
  .timeline-block-right {
    float: none;
    width: 100%;
  }

  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }
}

/******************************* Projects *****************************/
.portfolio-wrapper {
  background: var(--primary-light-grey);
  padding: 2rem 0;
}

.portfolio-wrapper h1 {
  color: var(--primary-orange);
}

.project-card {
  width: 22rem !important; 
  height: 24rem !important;
  margin-bottom: 2rem;
  border: .2rem solid black !important;
  transition: 0.20s;
}

.project-card:hover {
  transform: scale(1.045);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 1)
}

.project-card img {
  cursor: pointer;
  object-fit: fill !important;
  height: 15rem;
  width: 100%;
}

.text-bold {
  font-weight: bold;
}

.project-card-stack {
  margin: .5rem;
  border: 2px solid white;
  padding: .2rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
}

.card-status {
  position: absolute;
  right: 0;
  color: #000000 !important;
  font-weight: bold !important;
  font-size: 1rem !important;
}

.card-txt {
  position: relative;
}